import React from 'react';

import Projects from './Projects';

const Home = () => {
  return (
    <>
      <Projects />
    </>
  );
};

export default Home;
