import React from 'react';
import Container from 'utils/Container';

const NotFound = () => {
  return (
    <Container>
      <div>404</div>
    </Container>
  );
};

export default NotFound;
